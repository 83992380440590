<template>
  <li class="nav-item" :class="{ dropdown: hasChildren }">
    <router-link v-if="type == 'route'" :to="navigateTo" class="btn btn-link">{{ title }}</router-link>
    <a v-if="type == 'external'" :href="navigateTo" class="btn btn-link">{{ title }}</a>
    <a v-if="type == 'anchor'" href="#" v-scroll-to="{ el: navigateTo, offset: parsedOffset }" class="btn btn-link">{{ title }}</a>
    <a v-if="type == 'emit'" @click="emit(navigateTo)" class="btn btn-link">{{ title }}</a>

    <!-- Dropdown Tear -->
    <ul v-if="hasChildren" class="menu">
      <div class="accordion">
        <input type="radio" id="TODO" name="mobile-nav" hidden />
        <label class="accordion-header" for="TODO">{{ title }}</label>
      </div>
      <div class="accordion-body">
        <ul class="menu">
          <navItemMobile
            v-for="(item, index) in items"
            v-on="$listeners"
            :type="item.itemType"
            :navigateTo="item.navigateTo"
            :title="item.title"
            :offset="offset"
            :items="item.items"
            :key="'menu-' + index"
            class="menu-item"
          />
        </ul>
      </div>
    </ul>
  </li>
</template>

<style></style>

<script>
import navItemMobile from '@/components/navItemMobile';

export default {
  name: 'navItemMobile',
  components: {
    navItemMobile,
  },
  props: {
    type: String,
    navigateTo: String,
    title: String,
    offset: [String, Number],
    items: [Array],
    accordion: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    parsedOffset() {
      return parseInt(this.offset);
    },
    hasChildren() {
      return this.items && this.items.length > 0;
    },
  },
  methods: {
    emit(emitPath) {
      emitPath = emitPath.split('/');
      if (emitPath.length === 2) {
        let eventName = emitPath[0];
        let eventValue = emitPath[1];
        this.$root.$emit(eventName, eventValue);
      } else {
        this.$emit('toast', { butter: 'error', message: 'Emit Path Not Improperly Structured!' });
      }
    },
  },
  data() {
    return {
      authCode: '',
    };
  },
};
</script>
